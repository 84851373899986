@import url(https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
body, html {
    height: 100%
}

h1-header {
    color: rgb(255, 255, 255);
    font-family: 'Times New Roman', Times, serif;
    font-weight: 700;
    font-size: 7vh;
    text-align: center;
    text-transform: uppercase;
}

h2 {
    color: rgb(0, 0, 0); 
}

.strikethrough {
    text-decoration: line-through;
}


.jumbotron-2 {
    background-color: black;
}

.jumbotron {
    min-height: 45rem;
    height: 45rem;
    background-repeat: no-repeat;
    background-clip: border-box;
    text-align: center;
    vertical-align: center;
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 0px;
}

.pen-book-image {
    background-size: cover;
    height: 20vh;
    width: 40vw;
    align-items: center;
    margin-bottom: auto;
    margin-left: 2rem;
    margin-right: auto;
    margin-bottom: auto;
}


.image-book {
    height: 100%;

}


.hero-text {
    text-align: center;
    position: absolute;
    font-family: serif;
    font-weight: bolder;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
  }

.animation-slide-in {
    animation-duration: 1.5s;
    animation-name: slidein;
    animation-timing-function: ease-in-out;
}

@keyframes slidein {
    from {
      margin-left: 100%;
      width: 100%;
    }
  
    to {
      margin-left: 0%;
      width: 100%;
    }
  }
.animation-solidify {
    animation-duration: 1.5s;
    animation-name: fadeIn;
    animation-timing-function: ease-in-out;
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
 } 


.jumbotron-hero {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/static/media/HomePageJumboTron.5d175591.jpg);
    background-size: cover;
    background-clip:inherit;
    overflow: hidden;
    position: relative;
    bottom: 0%;
    height: 100%;
}


.vertical-center {
    min-height: 60vh;
    text-align: center;
    display: flex;
    align-items: center;
  }


.bg-card1 {
    background-color: tan;
}

 .center-text {
     text-align: center;
 }


.margin-jumbo {
    margin-bottom: 0px;
    background-color: tan;
}

 .margin-bottomcustom {
     margin-bottom: 3rem; 
     margin-left: 0;
 }

 .expert-team-section {
     margin-top: 0px;
     background-color: white;
     padding-top: 3rem;
     padding-left: 2rem;
 }

 .margin-top-cards {
     margin-top: 2rem;
 }



 
.bg-black {
    background-color: black;
}

.custom-link-text {
    color: white;
}

.custom-link-text:hover {
    color: tan;
}



/*FOOTER*/

footer {
  background: #16222A;
  background: linear-gradient(59deg, #171818, #000a11);
  color: white;
  margin-top:0px;
  position: bottom;
}

footer a {
  color: #fff;
  font-size: 14px;
  transition-duration: 0.2s;
}

footer a:hover {
  color: #FA944B;
  text-decoration: none;
}

.copy {
  font-size: 12px;
  padding: 10px;
  border-top: 1px solid #FFFFFF;
}

.footer-middle {
  padding-top: 2em;
  color: white;
}


/*SOCİAL İCONS*/

/* footer social icons */

ul.social-network {
  list-style: none;
  display: inline;
  margin-left: 0 !important;
  padding: 0;
}

ul.social-network li {
  display: inline;
  margin: 0 5px;
}


/* footer social icons */

.social-network button.icoFacebook:hover {
  background-color: #3B5998;
}

.social-network button.icoLinkedin:hover {
  background-color: #007bb7;
}

.social-network button.icoFacebook:hover i,
.social-network button.icoLinkedin:hover i {
  color: #fff;
}

.social-network button.socialIcon:hover,
.socialHoverClass {
  color: #44BCDD;
}

.social-circle li button {
  display: inline-block;
  position: relative;
  margin: 0 auto 0 auto;
  border-radius: 50%;
  text-align: center;
  width: 30px;
  height: 30px;
  font-size: 15px;
}

.social-circle li i {
  margin: 0;
  line-height: 30px;
  text-align: center;
}

.social-circle li button:hover i,
.triggeredHover {
  -moz-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -ms--transform: rotate(360deg);
  transform: rotate(360deg);
  transition: all 0.2s;
}

.social-circle i {
  color: #595959;
  transition: all 0.8s;
}

.social-network button {
  background-color: #F9F9F9;
}

.jumbotron-fullpage {
    margin-bottom: 0px;
    display: flex; 
    width: 80%; 
    background-clip: border-box;
    background-repeat: no-repeat;
    text-align: center;
    height: 80vh; 
    align-items: center;
}

.jumbotron-hero-services {
    background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(/static/media/doctor-ipad.fe98a0a9.jpg);
    background-size: cover;
    background-clip:inherit;
    overflow:auto;
    position: relative;
    bottom: 100%;
     
}

.jumbotron-title {
    font-family: 'Times New Roman', Times, serif;
    color: white; 
    font-weight: 700; 
    font-size: 8vh; 
}

.jumbotron-container {
    padding-left: 0px;
    padding-right: 0px;     
}

.jumbotron-container-text {
    color: white;
}


@media only screen and (min-width: 991px){
    .vertical-center {
        height: 94vh; 
        display: flex;
        align-items: center;
    }

}

@media only screeen and (max-width: 800px) {
    .vertical-center {
        height: 82vh;
        display: flex;
        align-items: center;
    }
}

@media only screen and (max-width: 600px) {
    .vertical-center {
        height: 70vh; 
        display: flex;
        align-items: center;
    }
}

.vertical-center {
    min-height: 82vh;
    /* These two lines are counted as one :-)       */
    

 @media only screen and (min-width: 991px){
        height: 95vh; 

       }
    display: flex;
    align-items: center;
  }


.container-down-arrow {
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    align-items: center;
    background-color: black;
    height: 15vh; 
    color: white; 
    font-weight: 700;
    font-size: 10vh;
    bottom: 0%; 
    position: absolute;
    vertical-align: top;
    padding-bottom: 10vh;
}

.services-card-section {
    background: linear-gradient(315deg, #537895 0%, #09203f 74%);
}


.auto-notify-census-image {
    background-image: url("https://images.pexels.com/photos/3683036/pexels-photo-3683036.jpeg");
    background-size: cover;
    background-clip:inherit;
    overflow: hidden;
    position: relative;
    bottom: 0%;
    height: 50vh; 
    width: 100%;
}

.animation-solidify {
    animation-duration: 1.5s;
    animation-name: fadeIn;
    animation-timing-function: ease-in-out;
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
 }
 
 
 .h2-card-title {
     font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
     font-size: 20px; 

 }

