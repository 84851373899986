.bg-black {
    background-color: black;
}

.custom-link-text {
    color: white;
}

.custom-link-text:hover {
    color: tan;
}

