@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

body, html {
    height: 100%
}

h1-header {
    color: rgb(255, 255, 255);
    font-family: 'Times New Roman', Times, serif;
    font-weight: 700;
    font-size: 7vh;
    text-align: center;
    text-transform: uppercase;
}

h2 {
    color: rgb(0, 0, 0); 
}

.strikethrough {
    text-decoration: line-through;
}


.jumbotron-2 {
    background-color: black;
}

.jumbotron {
    min-height: 45rem;
    height: 45rem;
    background-repeat: no-repeat;
    background-clip: border-box;
    text-align: center;
    vertical-align: center;
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 0px;
}

.pen-book-image {
    background-size: cover;
    height: 20vh;
    width: 40vw;
    align-items: center;
    margin-bottom: auto;
    margin-left: 2rem;
    margin-right: auto;
    margin-bottom: auto;
}


.image-book {
    height: 100%;

}


.hero-text {
    text-align: center;
    position: absolute;
    font-family: serif;
    font-weight: bolder;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
  }

.animation-slide-in {
    animation-duration: 1.5s;
    animation-name: slidein;
    animation-timing-function: ease-in-out;
}

@keyframes slidein {
    from {
      margin-left: 100%;
      width: 100%;
    }
  
    to {
      margin-left: 0%;
      width: 100%;
    }
  }
.animation-solidify {
    animation-duration: 1.5s;
    animation-name: fadeIn;
    animation-timing-function: ease-in-out;
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
 } 


.jumbotron-hero {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("HomePageJumboTron.jpg");
    background-size: cover;
    background-clip:inherit;
    overflow: hidden;
    position: relative;
    bottom: 0%;
    height: 100%;
}


.vertical-center {
    min-height: 60vh;
    text-align: center;
    display: flex;
    align-items: center;
  }


.bg-card1 {
    background-color: tan;
}

 .center-text {
     text-align: center;
 }


.margin-jumbo {
    margin-bottom: 0px;
    background-color: tan;
}

 .margin-bottomcustom {
     margin-bottom: 3rem; 
     margin-left: 0;
 }

 .expert-team-section {
     margin-top: 0px;
     background-color: white;
     padding-top: 3rem;
     padding-left: 2rem;
 }

 .margin-top-cards {
     margin-top: 2rem;
 }



 