.jumbotron-fullpage {
    margin-bottom: 0px;
    display: flex; 
    width: 80%; 
    background-clip: border-box;
    background-repeat: no-repeat;
    text-align: center;
    height: 80vh; 
    align-items: center;
}

.jumbotron-hero-services {
    background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("doctor-ipad.jpg");
    background-size: cover;
    background-clip:inherit;
    overflow:auto;
    position: relative;
    bottom: 100%;
     
}

.jumbotron-title {
    font-family: 'Times New Roman', Times, serif;
    color: white; 
    font-weight: 700; 
    font-size: 8vh; 
}

.jumbotron-container {
    padding-left: 0px;
    padding-right: 0px;     
}

.jumbotron-container-text {
    color: white;
}


@media only screen and (min-width: 991px){
    .vertical-center {
        height: 94vh; 
        display: flex;
        align-items: center;
    }

}

@media only screeen and (max-width: 800px) {
    .vertical-center {
        height: 82vh;
        display: flex;
        align-items: center;
    }
}

@media only screen and (max-width: 600px) {
    .vertical-center {
        height: 70vh; 
        display: flex;
        align-items: center;
    }
}

.vertical-center {
    min-height: 82vh;
    /* These two lines are counted as one :-)       */
    

 @media only screen and (min-width: 991px){
        height: 95vh; 

       }
    display: flex;
    align-items: center;
  }


.container-down-arrow {
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: center;
    align-items: center;
    background-color: black;
    height: 15vh; 
    color: white; 
    font-weight: 700;
    font-size: 10vh;
    bottom: 0%; 
    position: absolute;
    vertical-align: top;
    padding-bottom: 10vh;
}

.services-card-section {
    background: linear-gradient(315deg, #537895 0%, #09203f 74%);
}


.auto-notify-census-image {
    background-image: url("https://images.pexels.com/photos/3683036/pexels-photo-3683036.jpeg");
    background-size: cover;
    background-clip:inherit;
    overflow: hidden;
    position: relative;
    bottom: 0%;
    height: 50vh; 
    width: 100%;
}

.animation-solidify {
    animation-duration: 1.5s;
    animation-name: fadeIn;
    animation-timing-function: ease-in-out;
}

@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
 }
 
 
 .h2-card-title {
     font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
     font-size: 20px; 

 }
